.fortuneWheel {
    font-family: sans-serif;
    text-align: center;
}

.readout {
    position: absolute;
    bottom: 14vh;
    left: 21vw;
}

.selector {
    position: absolute;
    top: 6.5vh;
    right: 24vw;
    font-size: 40px;
    z-index: 3;
}

.result {
    font-size: 50px;
    color: rgb(0, 180, 120);
}

.spin-button{
    z-index: 100;
}

input {
    font-size: 16px;
    border: none;
    border-bottom: 1px solid black
}