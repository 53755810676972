body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --color-first: #D9ED92;
    --color-second: #B5E48C;
    --color-third: #99D98C;
    --color-fourth: #76C893;
    --color-fifth: #52B69A;
    --color-sixth: #34A0A4;
    --color-seventh: #168AAD;
    --color-eight: #1A759F;
    --color-ninth: #1E6091;
    --color-tenth: #184E77;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* This will center the content vertically in the viewport */
    background-image: url("https://picsum.photos/1920/1080");
    background-size: cover;
}

@media only screen and (max-width: 800px) {
    .background {
        background-image: url("https://picsum.photos/1080/1920");
    }
}

.input-answer {
    color: whitesmoke !important;
    font-size: 20px !important;
    font-weight: bold !important;
    text-align: center !important;
    padding: 5px !important;
    -webkit-text-stroke: 1px black !important;
}

.buttons {
    padding-top: 40px;
}

.answer1 {
    background-color: var(--color-first) !important;
}

.answer2 {
    background-color: var(--color-second) !important;
}

.answer3 {
    background-color: var(--color-third) !important;
}

.answer4 {
    background-color: var(--color-fourth) !important;
}

.answer5 {
    background-color: var(--color-fifth) !important;
}

.answer6 {
    background-color: var(--color-sixth) !important;
}

.answer7 {
    background-color: var(--color-seventh) !important;
}

.answer8 {
    background-color: var(--color-eight) !important;
}

.answer9 {
    background-color: var(--color-ninth) !important;
}

.answer10 {
    background-color: var(--color-tenth) !important;
}

.answer-true {
    background-color: #386641 !important;
}

.answer-false {
    background-color: #bc4749 !important;
}