.QuestionSlide {
}

.title {
    font-size: 40px;
    color: black;
    font-weight: bold;
    background-color: white;
    padding: 5px;
    font-family: Montserrat, sans-serif;
}

.label {
    font-size: 20px;
    font-weight: bold;
    padding-left: 5px;
    padding-top: 5px;
}

.video {
    margin: auto;
    width: 50%;
    padding: 10px;
}