.Login {
}

.title {
    color: black;
    font-weight: bold;
    background-color: white;
    padding: 5px;
}

.createQuiz {
    font-weight: bold;
    color: white;
    text-decoration: none;
    right: 5px;
}
