.Create {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    padding-bottom: 20vh;
    background-size: cover;
}

.form {
    font-weight: bold;
}

.answer-check {

}